import React from "react";
import $ from "jquery";
import { StylesManager, Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { useCallback } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import { marked, use } from "marked";
import { SvgRegistry } from "survey-core";
import "survey-core/defaultV2.css";
import "jquery-bar-rating/dist/themes/fontawesome-stars.css";
import "./index.css";
import { json } from "./json"
import * as SurveyCore from "survey-core";
import "jquery-bar-rating/jquery.barrating.js";
import * as widgets from "surveyjs-widgets";




//Reqister icon from SVG
var svg = '<svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg"><path d="M11.35 1.34999L10.65 0.649994L6.05002 5.24999L1.35002 0.649994L0.650024 1.34999L5.25002 6.04999L0.650024 10.65L1.35002 11.35L6.05002 6.74999L10.65 11.35L11.35 10.65L6.75002 6.04999L11.35 1.34999Z"/></svg>';
SvgRegistry.registerIconFromSvg("v2check", svg);
SvgRegistry.registerIconFromSvg("moderncheck", svg);

window["$"] = window["jQuery"] = $;

StylesManager.applyTheme("defaultV2");
require("emotion-ratings/dist/emotion-ratings.js");
widgets.emotionsratings(SurveyCore);
widgets.sortablejs(SurveyCore);
widgets.jquerybarrating(SurveyCore);

function SurveyComponent() {
    const survey = new Model(json);
    survey.allowPartialResponses = true;

  //eslint-disable-next-line
    const alertResults = useCallback((sender) => {
    const results = "Result JSON:\n" + JSON.stringify(sender.data, null, 3);
    alert(results);
  }, []);
    
    //Make Marked support specifying image size in pixels
const renderer = {
    image: function (src, title, alt) {
    const sizeStr = ',size=';
    let i = src.indexOf(sizeStr);
    let height = '';
    let width = '';
    if (i > -1) {
      let str = src.substring(i + sizeStr.length);
      src = src.substring(0, i);
      i = str.indexOf('x');
      if (i > -1) {
        height = str.substring(0, i) + 'px';
        width = str.substring(i + 1) + 'px';
      }
    }
    let res = '<img src="' + src + '" alt="' + alt;
    if (height) res += '" height="' + height;
    if (width) res += '" width="' + width;
    return res + '">';
    }
};
use({ renderer });
survey.onTextMarkdown.add(function (survey, options) {
    //convert the markdown text to html
    var str = marked(options.text);
    // remove root paragraphs <p></p>
    str = str.substring(3);
    str = str.substring(0, str.length - 5);
    // set html
    options.html = str;
});

    survey.onComplete
.add(function (sender, options) 
{var today = new Date();
var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
var dateTime = date+' '+time;
var dateTimeMod = '{"datetime":"' + dateTime + '"}';
var dtObject = JSON.parse(dateTimeMod);
var Cid = window.location.search.slice(1);
var cid = '{"cid":"' + Cid + '"}';
var object = JSON.parse(cid); 
var Object1 = Object.assign(object, sender.data); 
var Object2 = Object.assign(dtObject, Object1);
var myObject = JSON.stringify(Object2);
var xhr = new XMLHttpRequest();
xhr.open("POST","https://crea-survey.ru/backend/json.php",false);
xhr.setRequestHeader("Content-type","application/json");
xhr.onreadystatechange = function(){
    if(xhr.readyState===4){console.log("xhr response:"+xhr.response)}
    alert(xhr.responseText);
 };
 xhr.send(myObject);
});
var storageName = "survey_podagra";
    function saveSurveyData(survey) {
        var data = survey.data;
        data.pageNo = survey.currentPageNo;
        window.localStorage.setItem(storageName, JSON.stringify(data));
    }
    survey.onPartialSend.add(function(sender){
        saveSurveyData(sender);
    });
    survey.onComplete.add(function(sender, options){
        saveSurveyData(sender);
    });
    
    survey.sendResultOnPageNext = true;
    var prevData = window.localStorage.getItem(storageName) || null;
    if(prevData) {
        var data = JSON.parse(prevData);
        survey.data = data;
        if(data.pageNo) {
            survey.currentPageNo = data.pageNo;
        }
    }

    
    return (<Survey model={survey} />);
    
}

export default SurveyComponent;